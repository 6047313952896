module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!logoPath) var logoPath = '';
if (!desc) var desc = ''
;
__p += '\r\n\r\n<div class="cbrand">\r\n  <img class="cbrand-logo" src="' +
((__t = ( logoPath )) == null ? '' : __t) +
'" alt="">\r\n  <p class="cbrand-desc">\r\n    ' +
((__t = ( desc )) == null ? '' : __t) +
'\r\n  </p>\r\n</div>\r\n';

}
return __p
}