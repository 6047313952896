module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!title) var title = ''
if (!desc) var desc = ''
;
__p += '\r\n<div class="cintro">\r\n  <div class="cintro-body">\r\n    ';
 if (title) { ;
__p += '\r\n      <h3 class="cintro-title">\r\n        ' +
((__t = ( title )) == null ? '' : __t) +
'\r\n      </h3>\r\n    ';
 } ;
__p += '\r\n    ';
 if (desc) { ;
__p += '\r\n      <p class="cintro-desc">\r\n        ' +
((__t = ( desc )) == null ? '' : __t) +
'\r\n      </p>\r\n    ';
 } ;
__p += '\r\n  </div>\r\n</div>\r\n';

}
return __p
}