module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!title) var title = ''
;
__p += '\r\n<h4 class="csubtitle">\r\n  ' +
((__t = ( title )) == null ? '' : __t) +
'\r\n</h4>\r\n';

}
return __p
}