module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!dataArr) var dataArr = [];
;
__p += '\r\n<nav class="ctab">\r\n  ';
 for (var i = 0; i < dataArr.length; i++) { ;
__p += '\r\n    <a class="ctab-item ' +
((__t = ( i === 0 ? 'active' : '' )) == null ? '' : __t) +
'" href="#">\r\n      ' +
((__t = ( dataArr[ i ] )) == null ? '' : __t) +
'\r\n    </a>\r\n  ';
 } ;
__p += '\r\n</nav>\r\n';

}
return __p
}