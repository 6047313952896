module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!iconPath) var iconPath = '';
if (!text) var text = ''
;
__p += '\r\n\r\n<div class="ccard">\r\n  <img class="ccard-icon" src="' +
((__t = ( iconPath )) == null ? '' : __t) +
'" alt="">\r\n  <p class="ccard-text">' +
((__t = ( text )) == null ? '' : __t) +
'</p>\r\n</div>\r\n';

}
return __p
}